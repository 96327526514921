<template>
    <div>
        <AssitingLivingsComponent />
        <footer-component />
    </div>
</template>

<script>
import AssitingLivingsComponent from "@/components/assiting-living/AssitingLivingsComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
    name: "AssitingLivings",
    title: "Top 10 Assisting Livings | Baja California Health Tourism",
    components: {
        AssitingLivingsComponent,
        FooterComponent,
    },
    mounted() {
        changeInformationSEO();
    }
};
</script>

<style scoped>
</style>

<template>
    <v-container class="fill-height text-left pa-0 ma-0" fluid>
        <v-row justify="start">
            <v-col cols="12">
                <v-card class="fill-height container--fluid pa-0 ma-0" flat tile width="100%">
                    <v-slide-x-transition appear>
                        <v-img class="grey lighten-2" height="300" src="@/assets/images/assisting_livings.jpg"
                            width="100%">
                            <div class="fill-height bottom-gradient" style="width: 100%">
                                <v-container class="fill-height align-end pb-15" fluid>
                                    <v-row align="end" justify="start">
                                        <v-col class="pl-5 pl-md-15" cols="12">
                                            <v-sheet :width="$vuetify.breakpoint.xs ? '100%' : 500"
                                                class="transition-swing panel-filter-color" elevation="10" height="170"
                                                light outlined rounded="lg">
                                                <v-container class="pa-5 ma-5" fluid>
                                                    <v-row align="start" justify="start" dense>
                                                        <v-col cols="12">
                                                            <div class="blue--text mx-1">
                                                                <span class="text-md-h4">
                                                                    The best of Baja California
                                                                </span>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <div class="indigo--text mx-1">
                                                                <span class="text-md-h6">
                                                                    Feel comfortable in any of our Assisting Livings for
                                                                    a
                                                                    well-deserved rest and prompt recovery.
                                                                </span>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-sheet>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-img>
                    </v-slide-x-transition>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <div class="boxdivider"></div>
        </v-row>

        <v-row justify="center">
            <SearchBar :data="items" @search="updateFilteredData" />
            <FilterStateMunicipality @filter-data="filterEvent" @filter-data-name="filterEventName" />
        </v-row>

        <v-row justify="center">
            <div class="d-flex fill-height fluid pt-8 pb-8 px-5 px-md-10" style="width: 100%">
                <v-container class="fill-height" fluid>
                    <v-row align="center" justify="start">
                        <v-col v-for="(item, index) in filteredData" :key="index" class="d-flex child-flex" cols="12"
                            sm="6" md="4">
                            <div class="fill-height">
                                <CardAssitingLiving :item="item" />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-row>
        <v-row justify="center" class="ma-10" v-if="loading">
            <div style="text-align: center;">
                <div>
                    Loading our list of excellence Assisting Livings, please be patient ...
                </div>
                <div style="display: inline-block;margin-top: -15px;">
                    <v-img src="@/assets/images/loading_new.gif" width="100px"></v-img>
                </div>
            </div>
        </v-row>
        <v-row justify="center" class="ma-10" v-else-if="!loading && items.length === 0">
            <div style="text-align: center;">
                <div>
                    There are no Assiting Livings in the catalogue...
                </div>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import services from "@/providers/AssitingLivingService";
import SearchBar from "@/components/searchBar/SearchBar";
import FilterStateMunicipality from "@/components/filter-state-municipality/FilterStateMunicipality";
import CardAssitingLiving from "@/components/card-assiting-living/CardAssitingLiving";

export default {
    name: "AssitingLivingsComponent",

    components: {
        SearchBar,
        FilterStateMunicipality,
        CardAssitingLiving
    },

    data: () => ({
        selectedId: 0,
        items: [],
        filteredData: [],
        loading: true,
        transparent: "rgba(255, 255, 255, 0)",
        message: "Loading our list of excellence Assisting Livings, please be patient ...",
    }),
    methods: {
        filterEventName(value) {
            let s = "";
            if (value.province) {
                s = `${value.province}, México `
            }
            if (value.municipality) {
                s = `${value.municipality}, ${s}`
            }
            if (s !== "") {
                s = `of ${s}`;
            }
            const title = document.getElementById("title-site");
            title.textContent = `Top 10 Assiting Living ${s}| Baja California Health Tourism`;
        },
        filterEvent(value) {
            this.getAssitingLivings(value.province, value.municipality);
        },
        getAssitingLivings(province = '', municipality = '') {
            this.loading = true;
            services.getViewList(true, province, municipality).then((record) => {
                this.items = record.value;
                this.filteredData = [...this.items];

                if (this.items.length === 0) {
                    this.message = "There are no Assiting Livings in the catalogue...";
                }
            }).catch(()=>{
                this.message = "There are no Assiting Livings in the catalogue...";
            }).finally(()=>{
                this.loading = false;
            });
        },

        updateFilteredData(filteredData) {
            this.filteredData = filteredData;
        },
    },
    mounted() {
        this.items = [];
        this.filteredData = [];
    },
};
</script>

<style scoped>
.superior-derecha {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 80px;
    height: 80px;
}

.superior-derecha-segunda {
    position: absolute;
    top: 66px;
    right: -6px;
    width: 80px;
    height: 80px;
    border-radius: 50% !important;
}

.superior-derecha-tercera {
    position: absolute;
    top: 150px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50% !important;
}

.superior-derecha-cuarta {
    position: absolute;
    top: 218px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}

.v-btn {
    text-transform: none !important;
}

.backcolor {
    background-color: transparent;
}

.panel-filter-color {
    background: rgba(255, 255, 255, 0.767) 100%;
}

.boxdivider {
    background-color: transparent;
    width: 100px;
    height: 20px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom-width: 3px;
    border-bottom-color: blueviolet;
    border-bottom-style: solid;
    display: block;
}

.zoom-img {
    overflow: hidden;
    border-radius: 10px;
}

.zoom-img>img {
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}

.zoom-img:hover>img {
    transform: scale(1.05);
}

.zoom-image {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    /* VERZÖGERUNG */
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    overflow: hidden;
}

.zoom-image:hover {
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    -ms-transform: scale(1.07);
    -o-transform: scale(1.07);
    transform: scale(1.07);
}
</style>
